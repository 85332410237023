$(function(){
  $('.slider').slick({
    //自動切り替えON
    autoplay: false,
    //切り替えスピード
    autoplaySpeed: 4500,
    // 表示させるスライド数 [初期値:1]
    slidesToShow: 4,
    // 一度に移動させるスライド数 [初期値:1]
    slidesToScroll: 4,
    // レスポンシブ設定
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ],
    // 矢印キーでスライドを切り替えるか [初期値:true]
    //accessibility: true,

    // スライドの高さが違うときに自動調整するか [初期値:false]
    //adaptiveHeight: false,

    // 自動再生するか [初期値:false]
    //autoplay: true,

    // 自動再生で切り替えする時間(ミリ秒) [初期値:3000]
    //autoplaySpeed: 3500,

    // 前次ボタンを表示するか [初期値:true]
    arrows: false,

    // 別のスライドと連携したいときにクラス名を指定 [初期値:null]
    //asNavFor: null,

    // 矢印ボタンの生成位置を変更 [初期値:$('.my-slide)]
    //appendArrows: $('.my-slide'),

    // ドットナビゲーションの生成位置を変更 [初期値:$('.my-slide)]
    //appendDots: $('.my-slide'),

    // 前ボタンの要素を変更 [初期値:'<button type="button" class="slick-prev">Previous</button>']
    //prevArrow: '<a class="slick-prev" href="#">前へ</a>',

    // 次ボタンの要素を変更 [初期値:'<button type="button" class="slick-next">Next</button>']
    //nextArrow: '<a class="slick-next" href="#">次へ</a>',

    // slidesToShowが奇数のとき、現在のスライドを中央に表示するか [初期値:false]
    //centerMode: false,

    // centerMode:trueのとき、左右のスライドをチラ見せさせる幅 [初期値:'50px']
    //centerPadding: '60px',

    // ease-in,ease-in-outなどCSSのイージング [初期値:'ease']
    //cssEase: 'linear',

    // dots:trueのとき、ドットをサムネイルなどにカスタマイズ [初期値:n/a]
    //customPaging: function(slick, index){
    //var num = slick.$slides.eq(index).html();
    //return '<b>' + num + '</b>';
    //},

    // ドットナビゲーションを表示するか [初期値:false]
    //dots: false,

    // ドットナビゲーションのクラス名を変更 [初期値:slick-dots]
    //dotsClass: 'my-dots',

    // マウスドラッグでスライドの切り替えをするか [初期値:true]
    //draggable: true,

    // スライド切り替えをフェードするか [初期値:false]
    //fade: false,

    // クリックでメインのスライドを切り替えるか
    //focusOnSelect: true,

    // jQueryのイージング [初期値:'linear']
    //easing: 'linear',

    // スライドをループさせるか [初期値:true]
    //infinite: false,

    // infinite:falseのとき、両端のスライドをドラッグしようとした際のバウンドスクロール値 [初期値:0.15]
    //edgeFriction: 0.2,

    // 開始スライド（0から始まるので注意） [初期値:0]
    //initialSlide: 2,

    // 画像の遅延表示タイプ（ondemand/progressive) [初期値:'ondemand']
    //lazyLoad: 'ondemand',

    // モバイルファーストにするか [初期値:false]
    //mobileFirst: false,

    // autoplay:trueのとき、マウスフォーカスしたら一時停止させるか [初期値:true]
    //pauseOnFocus: true,

    // autoplay:trueのとき、マウスホバーしたら一時停止させるか [初期値:true]
    //pauseOnHover: true,

    // autoplay:trueのとき、ドットナビゲーションをマウスホバーしたら一時停止させるか [初期値:false]
    //pauseOnDotsHover: false,

    // レスポンシブ設定の基準（window/slider/min） [初期値:'window']
    //respondTo: 'window',

    // レスポンシブ設定
    //responsive: [
    //{
    //  breakpoint: 1024,     // 600〜1023px
    //  settings: {
    //    slidesToShow: 3,
    //    slidesToScroll: 3,
    //    infinite: true,
    //    dots: true
    //  }
    //},
    //{
    //  breakpoint: 600,      // 480〜599px
    //  settings: {
    //    slidesToShow: 2,
    //    slidesToScroll: 2
    //  }
    //},
    //{
    // breakpoint: 480,      // 〜479px
    // settings: {
    //   slidesToShow: 1,
    //   slidesToScroll: 1
    //  }
    //}
    //],

    // 行数 [初期値:1]
    //rows: 1,

    // スライド部分の要素のタグ名 [初期値:'']
    //slide: 'div',

    // rowsの値が2以上のとき、1行あたりに表示させるスライド数 [初期値:1]
    //slidesPerRow: 2,

    // 表示させるスライド数 [初期値:1]
    //slidesToShow: 2,

    // 一度に移動させるスライド数 [初期値:1]
    //slidesToScroll: 2,

    // スライド/フェードさせるスピード（ミリ秒） [初期値:300]
    //speed: 400,

    // スワイプを検知するか [初期値:true]
    //swipe: true,

    // slidesToScrollの値に関係なく、マウスドラッグやスワイプでスライドさせる際は1スライドずつ動かす [初期値:false]
    //swipeToSlide: false,

    // タッチでスライドさせるか [初期値:true]
    //touchMove: true,

    // (1/touchThreshold)*スライダーの横幅 分マウスドラッグするとスライドされる [初期値:5]
    //touchThreshold: 5,

    // CSSのtransitionを使用するか [初期値:true]
    //useCSS: true,

    // CSSのtransformを使用するか [初期値:true]
    //useTransform: true,

    // 横幅がバラバラなスライドにするか [初期値:false]
    //variableWidth: false,

    // 縦方向にスライドさせるか [初期値:false]
    //vertical: true,

    // 縦方向のスワイプを有効にするか [初期値:false]
    //verticalSwiping: false,

    // スライドの順番を逆にするか [初期値:false]
    //rtl: false,

    // スライドアニメーション中サムネイルをクリックしたとき反応させないか [初期値:true]
    //waitForAnimate: true,

    // z-index値 [初期値:1000]
    //zIndex: 1000
    });
  });
